import {getCategories, getDepots, getMessages, getNameForCategory} from "../services/restaurant";
import { MARKETPLACE_SEARCH_TYPES } from "../utils/enums";

export function listPromotionModel(data) {

    const getBusinessIdsName = () => {
            switch (item.list_type){
                case MARKETPLACE_SEARCH_TYPES.none:
                    const depots = getDepots();
                    return (item.business_ids || new Array(0)).map(m=> {
                        const depot = depots.find(f=> f.businessId === m);
                        return { key: m, value: (depot || {}).name || "" };
                    });
                case MARKETPLACE_SEARCH_TYPES.messages:
                    const messages = getMessages();
                    return (item.business_ids || new Array(0)).map(m=> {
                        const message = messages.find(f=> f.id === m);
                        return { key: m, value: (message || {}).label || (message || {}).title || "" };
                    });
                case MARKETPLACE_SEARCH_TYPES.categories:
                    const categories = getCategories();
                    return (item.business_ids || new Array(0)).map(m=> {
                        const category = categories.find(f=> f.id === m);
                        return { key: m, value: getNameForCategory(category) };
                    });
                default:
                    return [];
            }
    }

    const item = data || {};
    return {
        id: item.id || "",
        label: item.label || "",
        name_translations: item.name_translations || "",
        list_type: item.list_type || MARKETPLACE_SEARCH_TYPES.none,
        business_ids: getBusinessIdsName(),
        order: item.order || 0,
        visible: item.visible || false,
        positioning: item.positioning,
        form_factor: item.form_factor,
        row_items_count: item.row_items_count,
        max_preview_size: item.max_preview_size,
        margins: item.margins,
        highlighted: item.highlighted,
        hide_video_controls: item.hide_video_controls,
        shuffle: item.shuffle,
    };
}

export function listPromotionServerModel(data) {
    const item = data || {};
    return {
        Id: item.id,
        Name_translations: item.name_translations,
        List_type: item.list_type,
        Business_ids: item.business_ids.map(m=> m.key),
        Order: item.order,
        Visible: item.visible,
        Positioning: item.positioning,
        Form_factor: item.form_factor,
        Row_items_count: item.row_items_count,
        Max_preview_size: item.max_preview_size,
        Margins: item.margins,
        Label: item.label,
        Highlighted: item.highlighted,
        Hide_video_controls: item.hide_video_controls,
        Shuffle: item.shuffle,
    };
}