import {callServer} from "../utils/request-util";
import environment from "environment";

export function getMarketplaceStatisticsDaily(start, end){
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "callCenter/statistics/daily",
            method: "POST",
            data: {
                StartDate: start,
                EndDate: end,
            },
            roles: [environment.USER_ROLES.depot, environment.USER_ROLES.deliveryManager],
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function getEventStatistics(start, end){
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "callCenter/auditEvents",
            method: "POST",
            data: {
                StartDate: start,
                EndDate: end,
            },
            roles: [environment.USER_ROLES.depot, environment.USER_ROLES.deliveryManager],
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function getMarketplaceStatistics(start, end){
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "callCenter/statistics",
            method: "POST",
            data: {
                StartDate: start,
                EndDate: end,
            },
            roles: [environment.USER_ROLES.depot, environment.USER_ROLES.deliveryManager],
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function getBusinessStatisticsDaily(depotId, start, end){
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "callCenter/statistics/daily/business/" + depotId,
            method: "POST",
            data: {
                StartDate: start,
                EndDate: end,
            },
            roles: [environment.USER_ROLES.depot, environment.USER_ROLES.deliveryManager],
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function getBusinessStatisticsDailyByIds(businessIds, start, end){
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "callCenter/statistics/daily/business",
            method: "POST",
            data: {
                StartDate: start,
                EndDate: end,
                BusinessIds: businessIds
            },
            roles: [environment.USER_ROLES.depot, environment.USER_ROLES.deliveryManager],
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function getBusinessStatistics(depotId, start, end){
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "callCenter/statistics/business/" + depotId,
            method: "POST",
            data: {
                StartDate: start,
                EndDate: end,
            },
            roles: [environment.USER_ROLES.depot, environment.USER_ROLES.deliveryManager],
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function getBusinessStatisticsByIds(businessIds, start, end){
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "callCenter/statistics/business",
            method: "POST",
            data: {
                StartDate: start,
                EndDate: end,
                BusinessIds: businessIds
            },
            roles: [environment.USER_ROLES.depot, environment.USER_ROLES.deliveryManager],
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function getProductStatisticsDaily(productId, start, end){
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "callCenter/statistics/daily/product/" + productId,
            method: "POST",
            data: {
                StartDate: start,
                EndDate: end,
            },
            roles: [environment.USER_ROLES.depot, environment.USER_ROLES.deliveryManager],
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function getSuboptionStatisticsDaily(suboptionId, start, end){
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "callCenter/statistics/daily/subOption/" + suboptionId,
            method: "POST",
            data: {
                StartDate: start,
                EndDate: end,
            },
            roles: [environment.USER_ROLES.depot, environment.USER_ROLES.deliveryManager],
        }).then(response => resolve(response), error => reject(error.response));
    });
}