import {REMOVE_ALL_DATA} from '../common-actions';
import {
  ADD_RECONCILIATIONS_CONFIGURATIONS,
  ADD_RECONCILIATIONS_PERIODS,
  LOAD_RECONCILIATIONS_CONFIGURATIONS,
  LOAD_RECONCILIATIONS_PERIOD,
  LOAD_RECONCILIATIONS_PERIODS,
  RECONCILIATIONS_CONFIGURATIONS_DEPOTS_GET,
  RECONCILIATIONS_PERIODS_COLLAPSE_TABLE,
  RECONCILIATIONS_PERIODS_UNMOUNT_TABLE,
  UPDATE_RECONCILIATIONS_CONFIGURATIONS,
  UPDATE_RECONCILIATIONS_CONFIGURATIONS_MERGE,
} from './reconciliationModel-actions.js';

const initState = {
  data: {
    summary: { periods: [] },
    configurations: [],
    depots: []
  },
  config: {
    isCollapsedTable: false,
  }
};

export default function reconciliations(state = initState, action) {
  switch (action.type) {
    case REMOVE_ALL_DATA:
      return initState;
    case LOAD_RECONCILIATIONS_CONFIGURATIONS: {
      return {
        ...state,
        data: {
          ...state.data,
          configurations: action?.data || [],
        },
      };
    }
    case ADD_RECONCILIATIONS_CONFIGURATIONS: {
      return {
        ...state,
        data: {
          ...state.data,
          configurations: [...(state?.data?.configurations || []), action?.data],
        },
      };
    }
    case UPDATE_RECONCILIATIONS_CONFIGURATIONS: {
      return {
        ...state,
        data: {
          ...state.data,
          configurations: state.data?.configurations?.map(configuration =>
              configuration.id === action.data.id ? action.data : configuration
          ),
        },
      };
    }
    case UPDATE_RECONCILIATIONS_CONFIGURATIONS_MERGE: {
      return {
        ...state,
        data: {
          ...state.data,
          mergedModel: action.data,
        }
      }
    }

    case RECONCILIATIONS_PERIODS_COLLAPSE_TABLE: {
      return {
        ...state,
        config: {
          ...state.config,
          isCollapsedTable: action.data
        }
      }
    }

    case RECONCILIATIONS_PERIODS_UNMOUNT_TABLE: {
      return {
        ...state,
        config: {
          ...state.config,
          isCollapsedTable: false
        }
      }
    }
    case RECONCILIATIONS_CONFIGURATIONS_DEPOTS_GET: {

      return {
        ...state,
        data: {
          ...state.data,
          depots: action?.data.depots || [],
        },
      };
    }
    case LOAD_RECONCILIATIONS_PERIODS: {
      return {
        ...state,
        data: {
          ...state.data,
          summary: { ...action?.data },
        },
      };
    }
    case ADD_RECONCILIATIONS_PERIODS: {
      return {
        ...state,
        data: {
          ...state.data,
          // summary: {...state?.data?.summary, Periods: [...(state?.data?.summary.Periods || []), action?.data]},
        },
      };
    }
    case LOAD_RECONCILIATIONS_PERIOD: {
      let result = [...state?.data?.summary.periods];
      if (action?.data) {
        const findPeriod = result.find(e => e.id === action?.data.id);
        if (findPeriod) {
          result = result.map(e => {
            return findPeriod.id === e.id ? { ...action?.data } : { ...e };
          });
        } else {
          result.push(action?.data);
        }
      }

      return {
        ...state,
        data: {
          ...state.data,
          summary: { ...state?.data?.summary, periods: [...result] },
        },
      };
    }
    default:
      return state;
  }
}
