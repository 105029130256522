import { MODEL_TYPE, TYPE_PATTERN } from './enums';
import {
  createLocationModel,
  getAddressServerModel,
  getLocationServerModel,
  getOrderServerModel,
  getPaymentsServerModel,
} from '../services/order';
import i18n from '../i18n.js';

const formatRegExp = /{(\d+)}/g;

export function parseServerModel(obj, modelTypesDictionary, objectKey) {
  if (Array.isArray(obj)) {
    return obj.map(item =>
      parseServerModel(item, modelTypesDictionary, objectKey)
    );
  } else if (typeof obj === "object" && obj) {
    let newobj = {
      modelType: modelTypesDictionary[objectKey]
    };
    Object.keys(obj).forEach(key => {
      newobj[key.toFirstCharLowerCase()] = parseServerModel(
        obj[key],
        modelTypesDictionary,
        key
      );
    });
    return newobj;
  }

  return obj;
}

export function getParsedServerModel(obj) {
  if (Array.isArray(obj)) {
    return obj.map(item => getParsedServerModel(item));
  } else if (typeof obj === "object" && obj) {
    return obj.modelType && parseServerModelDictionary[obj.modelType]
      ? parseServerModelDictionary[obj.modelType](obj)
      : objectKeysToUpperLowerCase(obj, true);
  }
  return obj;
}

export const parseServerModelDictionary = {
  [MODEL_TYPE.order]: getOrderServerModel,
  [MODEL_TYPE.address]: getAddressServerModel,
  [MODEL_TYPE.location]: getLocationServerModel,
  [MODEL_TYPE.payments]: getPaymentsServerModel
};

export function objectKeysToUpperLowerCase(obj, upperCase = false) {
  if (Array.isArray(obj)) {
    return obj.map(item => objectKeysToUpperLowerCase(item, upperCase));
  } else if (typeof obj === "object" && obj) {
    let newobj = {};
    Object.keys(obj).forEach(
      key =>
        (newobj[
          upperCase ? key.toFirstCharUpperCase() : key.toFirstCharLowerCase()
        ] =
          Array.isArray(obj) || typeof obj === "object"
            ? objectKeysToUpperLowerCase(obj[key], upperCase)
            : obj[key])
    );
    return newobj;
  }
  return obj;
}

export function formatString(string) {
  let args = Array.prototype.slice.call(arguments, 1);
  return string.replace(formatRegExp, function(match, number) {
    let argNum = args[number];
    return argNum !== undefined ? argNum : match;
  });
}

export function findElementInArray(arr, prop, value) {
  let elem;
  arr.some(function(element) {
    if (element[prop] === value) {
      elem = element;
      return true;
    }
    return false;
  });
  return elem;
}

export function removeElementFromArray(arr, prop, value) {
  let elem;
  arr.some(function(element, index) {
    if (element[prop] === value) {
      elem = element;
      arr.splice(index, 1);
      return true;
    }
    return false;
  });
  return elem;
}

export const deleteKeysIfNull = (obj) => {
  if(!obj || typeof obj !== 'object') return null

  return Object.fromEntries(
    Object.entries(obj).reduce((acc, [key, value]) => {
      if (value !== null) {
        acc.push([key, value]);
      }
      return acc;
    }, [])
  )
}

export function mergeArrays(
  source,
  newArray,
  accessorSource = "id",
  accessorNewArray = "id"
) {
  if (!source || source.length === 0) return newArray;
  let newArrayFiltered = newArray.filter(f=> f);
  source = source.reduce((accumulator, currentValue) => {
    const index = newArrayFiltered
      .map(m => m[accessorNewArray])
      .indexOf(currentValue[accessorSource]);

    if (index > -1) {
      accumulator.push({ ...currentValue, ...newArrayFiltered[index] });
      newArrayFiltered.splice(index, 1);
    } else {
      accumulator.push(currentValue);
    }
    return accumulator;
  }, []);

  return source.concat(newArrayFiltered);
}

export const excludePropsFromObject = (obj, props = []) => {
  if(!(props || []).length) return obj;
  return Object.keys(obj).reduce((acc, key)=> { return props.includes(key) ? acc : { ...acc, [key]: obj[key] } }, {});
}

export function isSameObjects(obj1, obj2, excludedProps = []) {
  return JSON.stringify(excludePropsFromObject(obj1, excludedProps)) === JSON.stringify(excludePropsFromObject(obj2, excludedProps));
}

export function mapObject(obj, func) {
  const result = {};
  Object.keys(obj).forEach(key => (result[key] = func(obj[key])));
  return result;
}

export function someObject(obj, func) {
  return Object.keys(obj).some(key => func(obj[key]));
}

export function isValidStringNoSpaces(value) {
  return value && typeof value === "string" && value.indexOf(" ") < 0;
}

export function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0) {
    byteString = atob(dataURI.split(",")[1]);
  } else {
    byteString = unescape(dataURI.split(",")[1]);
  }

  // separate out the mime component
  const mimeString = dataURI
    .split(",")[0]
    .split(":")[1]
    .split(";")[0];

  // write the bytes of the string to a typed array
  let ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

export function getRegionFromGoogle(googleRegions) {
  const arr = googleRegions.map ? googleRegions : googleRegions.getArray();
  return arr.map(region => createLocationModel(region.lat(), region.lng()));
}

export function isEmpty(value) {
  return value === "undefined" || value === null || value === "" || value === 0;
}

export function isEmptyMode(value) {
  return value === "undefined" || value === null || value === "";
}

export function isEmptyObject(value) {
  return Object.getPrototypeOf(value) === Object.prototype && Object.keys(value).length === 0;
}

export function isNullOrWhitespace(string) {
  if (typeof string === 'undefined' || string == null) return true;
  return string.replace(/\s/g, '').length < 1;
}

export function isValidKey(keycode) {
  return (keycode > 47 && keycode < 58)  || // number keys
        keycode === 32 || keycode === 13 || // spacebar & return key(s) (if you want to allow carriage returns)
        (keycode > 64 && keycode < 91)   || // letter keys
        (keycode > 95 && keycode < 112)  || // numpad keys
        (keycode > 185 && keycode < 193) || // ;=,-./` (in order)
        (keycode > 218 && keycode < 223);   // [\]' (in order)
}

export function getSeparatedValue(string, separator, indexTo) {
  const arr = string.split(separator).filter((value, index) => index < indexTo);
  return arr && arr.length ? arr.join(separator) : string;
}

export function fileFromRequestResult(requestResult) {
  const c = requestResult.headers["content-disposition"];
  let fileName = c
    .split(";")[1]
    .trim()
    .split("=")[1];
  fileName = fileName.replace(new RegExp('"', "g"), "");
  const octetStreamMime = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  const contentType = requestResult.headers["content-type"] || octetStreamMime;

  return {
    fileName,
    file: requestResult.data,
    contentType
  };
}

export function getPattern(typePattern) {
  switch (typePattern) {
    case TYPE_PATTERN.email:
      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    case TYPE_PATTERN.userName:
      return /^[a-z0-9]+$/;
    case TYPE_PATTERN.userNameWithSpaces:
      return  /^[a-zA-Z0-9\u0590-\u05FF\u0400-\u04FF ]+$/;
    case TYPE_PATTERN.slug:
      return /^[a-zA-Z0-9\-_]+$/;
    case TYPE_PATTERN.userPassword:
      return /^[a-zA-Z0-9]+$/;
    case TYPE_PATTERN.onlyNumbers:
      return /^[0-9]+$/;
    default:
      return null;
  }
}

export function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

export function defaultPromiseResolve() {
  return new Promise(resolve=> resolve());
}

export function moveArrayItem(items, fromItem, toItem, sortPropName = "order", uniqueKey) {
  const indexFrom = items
    .map(m=> m[uniqueKey || sortPropName])
    .indexOf(fromItem[uniqueKey || sortPropName]);
  const indexTo = items
    .map(m=> m[uniqueKey || sortPropName])
    .indexOf(toItem[uniqueKey || sortPropName]);

  items.splice(indexFrom, 1);
  items.splice(indexTo, 0, fromItem);
  return items.map((item, index)=> ({ ...item, [sortPropName]: index }));
}

export function isValidDate(date) {
  return date instanceof Date && !isNaN(date.valueOf())
}

export function getStringValue(value) {
  return value.replace(/\s/g, '');
}

export function removeItemFromArray(array, item) {
  const index = array.indexOf(item);
  if (index > -1) array.splice(index, 1);
}

export function createExtraOverride(product, extra, newObject, oldObject){
  const changedKeys = [];
  const oldChangeKeys = [];
  const findCurrentOverrides = extra && extra.overrides && extra.overrides.filter(o => o.id === newObject.id).map(o => o.name);
  let overrides = [];

  for(let key in newObject) {
    if (typeof newObject[key] !== "object" && oldObject[key] !== newObject[key]) {
      changedKeys.push(key);
    }
  }

  if(findCurrentOverrides) {
    changedKeys.forEach(k => {
      if(!findCurrentOverrides.includes(k)) {
        oldChangeKeys.push(k);
      }
    });
  }

  overrides = oldChangeKeys.concat(findCurrentOverrides || changedKeys).map(k => ({
    id: newObject.id,
    name: k,
    value: newObject[k]
  }));

  const findExtraInProduct = product && product.extras.find(e => e.id === extra.id);
  if(findExtraInProduct) {
    if(findExtraInProduct.overrides) {
      findExtraInProduct.overrides = [...findExtraInProduct.overrides.filter(o => o.id !== newObject.id), ...overrides];
    } else {
      findExtraInProduct.overrides = overrides;
    }
  } else {
    const extraOverrides = extra.overrides ? [...extra.overrides.filter(o => o.id !== newObject.id), ...overrides] : overrides;
    product.extras.push({...extra, overrides: extraOverrides});
  }
}

export function getLastCharacters(str, length = 1) {
  if(!str) return "";
  return str.substr(-length);
}

export function isContainsHeb(str) {
  return (/[\u0590-\u05FF]/).test(str || "");
}

export const formatNumber = (number, digits = 2) => {
  return (number && typeof number == "number" && !isNaN(parseFloat(number)) && isFinite(number))
    ? number.toFixed(digits)
    : (number || 0);
}

export const getTranslateTitle = (title) => {
  return title ? i18n.exists(title) ? i18n.t(title) : title : ''
}

export const convertToBoolean = value => {
  if(typeof value === "boolean") {
    return value;
  }
  const isString = typeof value === "string";
  if(isString && (value === "true" || value === "True")) {
    return true;
  }
  if(isString && (value === "false" || value === "False")) {
    return false;
  }

  return Boolean(value);
}

export const arrayToMap = (source) => {
  const map = new Map();
  if(!source) return map;
  source.forEach(item => {
    map.set(item.id, item);
  })
  return map;
}

export const mergeObjectValues = (obj1, obj2) => {
  const merged = {};

  for (const key in obj1) {
    merged[key] = obj2[key] !== null && obj2[key] !== undefined ? obj2[key] : obj1[key];
  }

  return merged;
}